import React, { useEffect, useState } from 'react';
import './Introduction.css';
import professionalPhoto from '../../assets/images/logo.png'; // Update with the correct path
import LinkedInNewsletterFeed from '../LinkedInNewsletterFeed/LinkedInNewsletterFeed';

const Introduction = () => {
    const roles = ["Skilled Enterprise Architect", "Author", "Cloud Computing Expert", "Digital Transformation Specialist",
        "Trainer", "Technology Leader", "Entrepreneur", "Mentor"];
    const [currentRole, setCurrentRole] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentRole((currentRole) => (currentRole + 1) % roles.length);
        }, 2000); // Change role every 2 seconds
        return () => clearInterval(intervalId); // Clean up interval on component unmount
    });
    return (
        <div id="introduction" className="introduction">
            <div className="intro-text">
                <h1>Ashutosh Shashi</h1>
                <div key={roles[currentRole]} className="animated-text-container">
                    {roles[currentRole]}
                </div>
                <p>
                    I am Ashutosh Shashi, a skilled enterprise architect passionate about bridging the gap between advanced technology and practical business solutions. With over 19 years of experience in the tech industry, I’ve developed a deep expertise in software architecture, cloud computing, and digital transformation.                </p>
                <p>
                    Continually pursuing knowledge and innovation has marked my journey in the tech world. I have authored three books on technology that reflect my commitment to sharing knowledge and best practices in the field. As a technology leader, I have guided teams through complex projects, delivering high-quality software solutions that drive business success.                </p>
                <p>
                    Outside of my professional life, I'm an avid learner and an enthusiastic mentor. I enjoy exploring the latest technological trends, contributing to tech communities, and helping the next generation of tech professionals grow.
                </p>
                <a className="libutton" href="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=ashutoshshashi" target="_blank" rel="noopener noreferrer">
                    Follow on LinkedIn
                </a>
            </div>

            <div className="intro-photo">
                <img src={professionalPhoto} alt="Ashutosh Shashi" />
                <LinkedInNewsletterFeed />
            </div>
        </div>
    );
};

export default Introduction;
