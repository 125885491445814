import React from 'react';
import './Footer.css'; // Import the CSS for Footer

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>Connect with me: </p>
                {/* Add links to your social network profiles */}
                <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/ashutoshshashi" className="footer-link">LinkedIn</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7133315405330214912" className="footer-link">Newsletter</a>
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/ashutoshshashi" className="footer-link">X</a>
                <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@ashutoshshashi" className="footer-link">Medium</a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@itinfocus" className="footer-link">YouTube</a>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/ashutosh-shashi" className="footer-link">GitHub</a>
                {/* Add any additional social media links here */}
            </div>
            <div className="copy-right">
                <p>© {new Date().getFullYear()} Ashutosh Shashi. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
