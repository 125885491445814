import React from 'react';
import './BlogSection.css'; // Import the CSS for BlogSection
import MediumBlogsFeed from '../MediumBlogsFeed/MediumBlogsFeed';
import YouTubeVlogsFeed from '../MediumBlogsFeed/YouTubeVlogsFeed';

const BlogSection = () => {
    return (
        <section id="blog" className="blog-section">
            <h2 className="blog-section-title">The Professional's Palette: Articles, Analysis, & Advocacy</h2>
            <p>
                The Professional's Palette' is where I share my journey of discovery and learning through articles, analysis, and advocacy, striving to contribute meaningfully to conversations that matter in our tech-driven era.
            </p>
            <div className="blog-list">
                <YouTubeVlogsFeed />
                <MediumBlogsFeed />
            </div>
        </section>
    );
};

export default BlogSection;
