import React from 'react';
import './Publications.css'; // Make sure to create this CSS file

const Publications = () => {
    const books = [
        {
            title: "Designing Applications for Google Cloud Platform: Create and Deploy Applications Using Java",
            url: "https://a.co/d/fK81ofF",
            cover: "https://m.media-amazon.com/images/I/61Egj3ASKSL._SY522_.jpg"
        },
        {
            title: "Brush-up java for Interview: Java concepts refresher",
            url: "https://a.co/d/gjULg83",
            cover: "https://m.media-amazon.com/images/I/61IvdkXSPnL._SY522_.jpg"
        },
        {
            title: "Re-Architecting Application for Cloud: An Architect’s reference guide",
            url: "https://a.co/d/8LvnHzB",
            cover: "https://m.media-amazon.com/images/I/61sRMtTQTML._SY522_.jpg" // Replace with actual image link
        }
    ];

    return (
        <div id='publications' className="publications">
            <h2>My Publications</h2>
            <ul>
                {books.map(book => (
                    <li key={book.url}>
                        <a href={book.url} target="_blank" rel="noopener noreferrer">
                            <img src={book.cover} alt={book.title} />
                            <p>{book.title}</p>
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Publications;
