import React from 'react';
import './MediumBlogsFeed.css';
const blogs = [
    {
        title: 'API-First Design',
        url: 'https://medium.com/@ashutoshshashi/api-first-design-92a52c79363e'
    },
    {
        title: 'Power of AI in Brainstorming',
        url: 'https://medium.com/@ashutoshshashi/power-of-ai-in-brainstorming-b12b8d426b77'
    },
    {
        title: 'Mastering Legacy System Integration',
        url: 'https://medium.com/@ashutoshshashi/mastering-legacy-system-integration-c3de879c8cb9'
    },
    {
        title: 'AI-Driven Data Analysis',
        url: 'https://medium.com/@ashutoshshashi/ai-driven-data-analysis-0b7c52362fcc'
    },
    {
        title: 'Transforming Software Development',
        url: 'https://medium.com/@ashutoshshashi/transforming-software-development-94785cbc2746'
    },
    {
        title: 'The Strategic Advantage of Hiring H1B Professionals for CXO Roles',
        url: 'https://medium.com/@ashutoshshashi/embracing-skill-over-status-af640a474f18'
    },
    {
        title: 'Smart Cities & AI: Tomorrow’s Urban Life',
        url: 'https://medium.com/@ashutoshshashi/smart-cities-ai-tomorrows-urban-life-573b33cad8dd'
    },
    {
        title: 'Stay ahead in a fast-forward world with AI acceleration',
        url: 'https://medium.com/@ashutoshshashi/stay-ahead-in-a-fast-forward-world-with-ai-acceleration-f4018c1df20e'
    },
    {
        title: 'AI in Software Development',
        url: 'https://medium.com/@ashutoshshashi/ai-in-software-development-87e666f572bd'
    },
    {
        title: 'The Evolutionary Gap between EdTech and E-Commerce',
        url: 'https://medium.com/@ashutoshshashi/the-evolutionary-gap-between-edtech-and-e-commerce-8c8f849492bb'
    },
    {
        title: 'AI in Retail: Transforming Shopping Experiences',
        url: 'https://medium.com/@ashutoshshashi/ai-in-retail-transforming-shopping-experiences-f38329c0351e'
    },
    {
        title: 'Navigating Customer and Partner Technical Collaborations',
        url: 'https://medium.com/@ashutoshshashi/navigating-customer-and-partner-technical-collaborations-5db597100ad7'
    },
    {
        title: 'Effective ways to gather and use customer feedback in software development',
        url: 'https://medium.com/@ashutoshshashi/effective-ways-to-gather-and-use-customer-feedback-in-software-development-08f9cfd4a743'
    },
    {
        title: 'A Sequential Approach to Resolving Technical Challenges',
        url: 'https://medium.com/@ashutoshshashi/a-sequential-approach-to-resolving-technical-challenges-7f12fe0cd007'
    },
    {
        title: 'The Rise of Serverless Computing: What Architects Need to Know',
        url: 'https://medium.com/@ashutoshshashi/the-rise-of-serverless-computing-what-architects-need-to-know-1c848f428c4e'
    },
    {
        title: 'Uncovering Hidden Design Patterns in Everyday Code',
        url: 'https://medium.com/@ashutoshshashi/uncovering-hidden-design-patterns-in-everyday-code-979da7d91689'
    },
    {
        title: '10 Advance Level Spring Boot Questions Every Developer Should Know',
        url: 'https://medium.com/@ashutoshshashi/10-advance-level-spring-boot-questions-every-developer-should-know-5d6693a71ca7'
    },
    {
        title: 'Design Patterns Overview',
        url: 'https://medium.com/@ashutoshshashi/design-patterns-overview-10d5988bbdb4'
    },
    {
        title: '10 Intermediate Level Spring Boot Questions Every Developer Should Know',
        url: 'https://medium.com/@ashutoshshashi/10-intermediate-level-spring-boot-questions-every-developer-should-know-0c5b0df903ba'
    },
    {
        title: 'Java Lambda, StreamAPI, Collections, and Multithreading Questions Every Developer Should Know',
        url: 'https://medium.com/@ashutoshshashi/java-lambda-streamapi-collections-and-multithreading-questions-every-developer-should-know-0145d061ff2e'
    },
    {
        title: 'Future-Proofing Your Architecture: Embracing Scalability and Flexibility',
        url: 'https://medium.com/@ashutoshshashi/future-proofing-your-architecture-embracing-scalability-and-flexibility-c1204927995a'
    },
    {
        title: '10 Basic Spring Boot Questions Every Developer Should Know',
        url: 'https://medium.com/@ashutoshshashi/10-basic-spring-boot-questions-every-developer-should-know-4fc8325e0c91'
    },
    {
        title: 'Architecture Blunders',
        url: 'https://medium.com/@ashutoshshashi/architecture-blunders-abdb7021f373'
    },
    {
        title: 'Creating Solution Architecture',
        url: 'https://medium.com/@ashutoshshashi/creating-solution-architecture-51942fbdfb66'
    },
    {
        title: 'Mitigating Risks When Choosing Tools and Technologies for Microservice Architecture and Cloud-Based…',
        url: 'https://medium.com/@ashutoshshashi/mitigating-risks-when-choosing-tools-and-technologies-for-microservice-architecture-and-cloud-based-3dace0ed56ca'
    },
    {
        title: 'When to use Cloud SQL Over Cloud Datastore',
        url: 'https://medium.com/@ashutoshshashi/when-to-use-cloud-sql-over-cloud-datastore-e741f6e6a21e'
    },
    {
        title: 'Using Java to interact with Cloud Storage',
        url: 'https://medium.com/@ashutoshshashi/using-java-to-interact-with-cloud-storage-5dfd3e30433f'
    },
    {
        title: 'Possessing and accumulating new technology and industry trends',
        url: 'https://medium.com/@ashutoshshashi/possessing-and-accumulating-new-technology-and-industry-trends-da638b353a85'
    },
    {
        title: 'Building trust relationship with the customer (client)',
        url: 'https://medium.com/@ashutoshshashi/building-trust-relationship-with-the-customer-client-3e187573187b'
    },
    {
        title: 'Digital Transformation Part-3',
        url: 'https://medium.com/@ashutoshshashi/digital-transformation-part-3-8244a2c8b5f4'
    },
    {
        title: 'Digital Transformation Part-2',
        url: 'https://medium.com/@ashutoshshashi/digital-transformation-part-2-a0ebb2b1334e'
    },
    {
        title: 'Digital Transformation Part-1',
        url: 'https://medium.com/@ashutoshshashi/digital-transformation-part-1-74ec174effb5'
    },
    {
        title: 'Cloud migration: Benefits and Risks',
        url: 'https://medium.com/@ashutoshshashi/cloud-migration-benefits-and-risks-74e1ca4ad0ef'
    },
    {
        title: 'Cloud Security: Vulnerabilities and Threats',
        url: 'https://medium.com/@ashutoshshashi/cloud-security-vulnerabilities-and-threats-c6f54d60650f'
    },
    {
        title: 'New proposed switch expressions in Java JDK 12',
        url: 'https://medium.com/@ashutoshshashi/new-proposed-switch-expressions-in-java-jdk-12-f309a506c026'
    },
    {
        title: 'Time to replace REST (HTTP/1.1) with gRPC (HTTP/2)',
        url: 'https://medium.com/@ashutoshshashi/time-to-replace-rest-http-1-1-with-grpc-http-2-5fd495428bb8'
    },
    {
        title: 'Ariel view of the application in design',
        url: 'https://medium.com/@ashutoshshashi/ariel-view-of-the-application-in-design-bd20e92a94e5'
    },
    {
        title: 'Choice of Managed Kubernetes and PaaS',
        url: 'https://medium.com/@ashutoshshashi/choice-of-managed-kubernetes-and-paas-e100add861ac'
    },


    // ... more blogs
];
const MediumBlogsFeed = () => {
    // const [posts, setPosts] = useState([]);
    // useEffect(() => {
    /* const fetchPosts = async () => {
         const corsProxy = 'https://cors-anywhere.herokuapp.com/';
         const mediumRssFeed = 'https://medium.com/feed/@ashutoshshashi';
         const parser = new Parser();
 
         try {
             const feed = await parser.parseURL(corsProxy + mediumRssFeed);
             setPosts(feed.items);
         } catch (error) {
             console.error('Error fetching Medium feed:', error);
         }
     };*/

    // fetchPosts();
    //  }, []);

    /*  const formatDate = (dateString) => {
          const date = new Date(dateString);
          const options = { month: 'long', day: 'numeric', year: 'numeric' };
          return date.toLocaleDateString('en-US', options); // Adjust the locale as needed
      };*/
    /*  const extractFirstImage = (htmlContent) => {
          const parser = new DOMParser();
          const htmlDocument = parser.parseFromString(htmlContent, "text/html");
          const image = htmlDocument.querySelector("img");
          return image ? image.src : null; // Return the image source or null if no image is found
      };*/

    return (
        <div id='mediumBlogs'>
            <section>
                <a href='https://medium.com/@ashutoshshashi' target="_blank" rel="noopener noreferrer">
                    <h2 className="medium-blogs-heading">Articles on Medium</h2> {/* Heading outside the .medium-blogs grid */}
                </a>
                <div className="medium-blogs">
                    {blogs.map(blog => (
                        <li key={blog.url}>
                            <a href={blog.url} target="_blank" rel="noopener noreferrer">
                                {blog.title}
                            </a>
                        </li>
                    ))}
                    <a href='https://medium.com/@ashutoshshashi' target="_blank" rel="noopener noreferrer">
                        <h3>Follow me and read More on Medium</h3>
                    </a>
                </div>
            </section>
        </div>
    );
};

export default MediumBlogsFeed;
