import React from 'react';
import './YouTubeVlogsFeed.css';

const YouTubeVlogsFeed = () => {
    // Manually curated list of videos
    const videos = [
        {
            id: '2uKGj9yQ85Y',
            title: 'Full Java Course with Spring Boot. Java and Spring Boot: Zero 2 Hero ',
            url: 'https://www.youtube.com/watch?v=2uKGj9yQ85Y',
            thumbnail: 'https://img.youtube.com/vi/2uKGj9yQ85Y/mqdefault.jpg'
        },
        {
            id: 'nHC6VwFwjTU',
            title: 'Crash course of Java and OOP Concepts with interview tips',
            url: 'https://www.youtube.com/watch?v=nHC6VwFwjTU',
            thumbnail: 'https://img.youtube.com/vi/nHC6VwFwjTU/mqdefault.jpg'
        },
        {
            id: '460tgCVX5sw',
            title: 'Docker and Spring-Boot)',
            url: 'https://www.youtube.com/watch?v=460tgCVX5sw',
            thumbnail: 'https://img.youtube.com/vi/460tgCVX5sw/mqdefault.jpg'
        },
        {
            id: '3SaIkZNfhto',
            title: 'Java, Maven, Spring-Boot, and Docker Starter course ',
            url: 'https://www.youtube.com/watch?v=3SaIkZNfhto',
            thumbnail: 'https://img.youtube.com/vi/3SaIkZNfhto/mqdefault.jpg'
        },
        {
            id: 'F1sG4b79I7o',
            title: 'Docker installation and setup on local ',
            url: 'https://www.youtube.com/watch?v=F1sG4b79I7o',
            thumbnail: 'https://img.youtube.com/vi/F1sG4b79I7o/mqdefault.jpg'
        },
        {
            id: '5M68EiZTQnE',
            title: 'Implementation of Builder Pattern in Java',
            url: 'https://www.youtube.com/watch?v=5M68EiZTQnE',
            thumbnail: 'https://img.youtube.com/vi/5M68EiZTQnE/mqdefault.jpg'
        },
        {
            id: 'Xsx5Fg1RKsk',
            title: 'Sealed Class (introduced in Java 15) ',
            url: 'https://www.youtube.com/watch?v=Xsx5Fg1RKsk',
            thumbnail: 'https://img.youtube.com/vi/Xsx5Fg1RKsk/mqdefault.jpg'
        },
        {
            id: 'Uu8bGdpKbLY',
            title: 'Complete polymorphism in java in 5 minutes',
            url: 'https://www.youtube.com/watch?v=Uu8bGdpKbLY',
            thumbnail: 'https://img.youtube.com/vi/Uu8bGdpKbLY/mqdefault.jpg'
        },
        {
            id: 'NA9b79Iac4o',
            title: 'Creating java and spring-boot docker container, run docker container from local',
            url: 'https://www.youtube.com/watch?v=NA9b79Iac4o',
            thumbnail: 'https://img.youtube.com/vi/NA9b79Iac4o/mqdefault.jpg'
        },
        {
            id: '5zAZCKrJ6k4',
            title: 'Java Multithreading in 6 minutes',
            url: 'https://www.youtube.com/watch?v=5zAZCKrJ6k4',
            thumbnail: 'https://img.youtube.com/vi/5zAZCKrJ6k4/mqdefault.jpg'
        },
        {
            id: '4cU34ybrPgI',
            title: 'Creating spring boot Micro-services, step-by-step',
            url: 'https://www.youtube.com/watch?v=4cU34ybrPgI',
            thumbnail: 'https://img.youtube.com/vi/4cU34ybrPgI/mqdefault.jpg'
        },
        {
            id: 'itinfocus',
            title: 'see more on YouTube channel IT in FOCUS...',
            url: 'https://www.youtube.com/channel/UCDX3pRK4Gp8nVKu_UhX5Okw?sub_confirmation=1',
            thumbnail: 'https://yt3.ggpht.com/Jz_MtZfKUMtF4RaEDEys0hAUTM109kY12ZyRoSWCDEDat-kZXVCdTigCfkp_XqQ720ZkFrsxbQ=s176-c-k-c0x00ffffff-no-rj'
        },
    ];

    return (
        <div id='vlog' className="youtube-vlogs">
            <a href='https://www.youtube.com/channel/UCDX3pRK4Gp8nVKu_UhX5Okw?sub_confirmation=1' target="_blank" rel="noopener noreferrer">
                <h2>YouTube Vlogs</h2>
            </a>
            <ul>
                {videos.map(video => (
                    <li key={video.id}>
                        <a href={video.url} target="_blank" rel="noopener noreferrer">
                            <img src={video.thumbnail} alt={video.title} />
                            <p>{video.title}</p>
                        </a>
                    </li>
                ))}
            </ul>
        </div >
    );
};

export default YouTubeVlogsFeed;
