import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Introduction from './components/Introduction/Introduction';
import BlogSection from './components/BlogSection/BlogSection';
import Publications from './components/Publications/Publications';
import ContactPage from './components/ContactPage/ContactPage';
import ProtectedRoute from './components/admin/ProtectedRoute';
import AdminPage from './components/admin/AdminPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import SignIn from './components/admin/SignIn';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Introduction />
          <Publications />
          <BlogSection />
          <ContactPage />
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/admin" element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            } />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;