import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './Header.css'; // Make sure to import the CSS file
import logo from '../../assets/images/logo.png';
import '../admin/AdminPage';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            // setIsUserSignedIn(!!user); // Set true if user is signed in, false otherwise
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="header-content">
                <a href="#top"> {/* This will navigate to the top of the page */}
                    <img src={logo} alt="Logo" className="logo" />
                </a>
                <button className="hamburger-menu" onClick={toggleMenu}>
                </button>
                <nav className={`navigation ${isMenuOpen ? 'active' : ''}`}>
                    <a href="#introduction" className="nav-item">Home</a>
                    <a href="#publications" className="nav-item">Publications</a>
                    <a href="#vlog" className="nav-item">Vlogs</a>
                    <a href="#mediumBlogs" className="nav-item">Blogs</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.udemy.com/user/ashutosh-shashi-4/" className="nav-item">Udemy</a>
                    <a href="#contact" className="nav-item">Contact</a>
                </nav>
            </div>
        </header>
    );
};

export default Header;
