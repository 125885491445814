import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const AdminPage = ({ user }) => {
    const [messages, setMessages] = useState([]);
    const db = getFirestore();

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                const querySnapshot = await getDocs(collection(db, 'contacts'));
                const fetchedMessages = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setMessages(fetchedMessages);
            };

            fetchData().catch(console.error);
        }
    }, [db, user]);

    return (
        <div>
            <h1>Admin Page</h1>
            {messages.map((message) => (
                <div key={message.id}>
                    <p><strong>Email:</strong> {message.email}</p>
                    <p><strong>Message:</strong> {message.message}</p>
                    {/* Display other message fields as needed */}
                </div>
            ))}
        </div>
    );
};

export default AdminPage;
