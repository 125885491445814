import React from 'react';
import './LinkedInNewsletterFeed.css'; // Make sure the path is correct

const LinkedInNewsletterFeed = () => {
    const newsletters = [
        {
            title: "IT in FOCUS",
            url: "https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7133315405330214912"
        },
        // ... other newsletter issues
    ];

    return (
        <div className="linkedin-newsletter">
            <a href='https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7133315405330214912' target="_blank" rel="noopener noreferrer">
                <h2>Subscribe LinkedIn Newsletter</h2>
            </a>
            <ul>
                {newsletters.map(newsletter => (
                    <li key={newsletter.url}>
                        <a class="libutton" href={newsletter.url} target="_blank" rel="noopener noreferrer"  >
                            <h3>{newsletter.title}</h3>
                        </a>
                    </li>
                ))}
            </ul>
        </div >
    );
};

export default LinkedInNewsletterFeed;
