import 'firebase/auth';         // For Firebase Authentication
import 'firebase/firestore';    // For Firebase Firestore
import 'firebase/database';     // For Firebase Realtime Database
import 'firebase/storage';      // For Firebase Storage
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBXmQ6xQVWamuFIz7rfw9cOuJkd4DOK_tQ",
    authDomain: "my-portfolio-582e7.firebaseapp.com",
    projectId: "my-portfolio-582e7",
    storageBucket: "my-portfolio-582e7.appspot.com",
    messagingSenderId: "562550322531",
    appId: "1:562550322531:web:b9e08b8a6d78e029920f56",
    measurementId: "G-WTYWYXYVP9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { db };
