import React, { useState } from 'react';
import './ContactPage.css';
import { db } from '../../firebaseConfig';
import { collection, doc, setDoc } from "firebase/firestore";


const ContactPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        country: '',
        customCountryCode: '', // For custom country code
        phone: '',
        message: ''
    });
    const contactsRef = collection(db, "contacts");
    const [errors, setErrors] = useState({});
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const countryCodes = [
        { code: 'US', number: '+1' },
        { code: 'IN', number: '+91' },
        { code: 'UK', number: '+44' },
        { code: 'FR', number: '+33' },
        { code: 'DE', number: '+49' },
        { code: 'JP', number: '+81' },
        { code: 'CN', number: '+86' },
        { code: 'CA', number: '+1' },
        { code: 'AU', number: '+61' },
        { code: 'Other', number: 'Other' }
        // ... more countries
    ];
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear errors on change
    };

    const validatePhoneNumber = (number) => {
        const phoneRegex = /^(\(\d{3}\)|\d{3})[ -]?\d{3}[ -]?\d{4}$/;
        if (!phoneRegex.test(number)) {
            return 'Invalid phone number format.';
        }

        // Remove country code and non-digit characters for sequence/repetition checks
        const digitsOnly = number.replace(/^\+\d{1,3}\s?/, '').replace(/\D/g, '');

        // Check for the same digit repeated all through
        if (/^(\d)\1+$/.test(digitsOnly)) {
            return 'Phone number cannot contain repeated digits only.';
        }

        // Check for sequential ascending or descending digits (basic check)
        if ('1234567890'.includes(digitsOnly) || '0987654321'.includes(digitsOnly)) {
            return 'Phone number cannot be sequential.';
        }

        return ''; // Return empty string if no errors
    };

    const validate = () => {
        let tempErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneError = validatePhoneNumber(formData.phone);
        if (formData.country === 'Other') {
            const countryCodeRegex = /^\+\d{1,3}$/;
            if (!countryCodeRegex.test(formData.customCountryCode)) {
                tempErrors.customCountryCode = 'Please enter a valid country code (e.g., +1).';
            }
        }
        else if (formData.phone && !formData.country) {
            tempErrors.country = 'Please select a country.';
        }
        if (formData.phone && phoneError) {
            tempErrors.phone = phoneError;
        }
        if (!emailRegex.test(formData.email)) {
            tempErrors.email = 'Please enter a valid email address.';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return; // Stop the submission if there are validation errors
        //try {
        /*await db.collection('contacts').add(formData);
        setSubmitSuccess(true);
        setFormData({
            email: '',
            country: '',
            customCountryCode: '', // For custom country code
            phone: '',
            message: ''
        });
    } catch (error) {
        console.error('Error saving to Firestore:', error);
    }*/
        console.log(db); // For debugging
        await setDoc(doc(contactsRef), {
            email: formData.email,
            countryCode: formData.country,
            customCountryCode: formData.customCountryCode,
            phone: formData.phone,
            message: formData.message,
        })
            .then(() => {
                console.log("Document successfully written!");
                // Handle successful submission (e.g., clear form, show message)
                setSubmitSuccess(true);
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
        /* await db.collection("contacts").add({
             email: formData.email,
             countryCode: formData.country.code,
             countryNumber: formData.country.number,
             customCountryCode: formData.customCountryCode,
             phone: formData.phone,
             message: formData.message
             // Add other fields as needed
         })
             .then(() => {
                 console.log("Document successfully written!");
                 // Handle successful submission (e.g., clear form, show message)
                 setSubmitSuccess(true);
             })
             .catch((error) => {
                 console.error("Error writing document: ", error);
                 // Handle errors
             });*/
        // Form submission logic goes here...
        console.log('Form Data Submitted:', formData);
    };

    return (
        <div id='contact' className="contact-container">
            <h1>Contact Me</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    {errors.email && <div className="error-message">{errors.email}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="country">Country:</label>
                    <select name="country" value={formData.country} onChange={handleChange}>
                        <option value="">Select Country</option>
                        {countryCodes.map(country => (
                            <option key={country.code} value={country.number}>
                                {country.code} ({country.number})
                            </option>
                        ))}
                    </select>
                    {errors.country && <div className="error-message">{errors.country}</div>}
                </div>
                {formData.country === 'Other' && (
                    <div className="form-group">
                        <label htmlFor="customCountryCode">Country Code:</label>
                        <input
                            type="text"
                            id="customCountryCode"
                            name="customCountryCode"
                            value={formData.customCountryCode}
                            onChange={handleChange}
                            placeholder="Enter country code with + sign"
                            required
                        />
                        {errors.customCountryCode && <div className="error-message">{errors.customCountryCode}</div>}
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="phone">Phone Number:</label>
                    <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
                    {errors.phone && <div className="error-message">{errors.phone}</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                    {errors.message && <div className="error-message">{errors.message}</div>}
                </div>
                <button type="submit" className={submitSuccess ? 'button-animate' : ''}>
                    {submitSuccess ? 'Submitted' : 'Send Message'}
                </button>
                {submitSuccess && (
                    <p className="success-message">
                        Thanks for reaching out to me. I will reach out to you soon!
                    </p>
                )}
            </form>
        </div>
    );
};

export default ContactPage;
