import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        return () => unsubscribe();
    }, [auth]);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // Loading indicator
    }

    return isAuthenticated ? children : <Navigate to="/signin" />;
};

export default ProtectedRoute;
